import { AuthService } from './../../services/auth.service';
import { Component, OnChanges, OnInit } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnChanges {
  showVideo: boolean= false;
  nomeUtente:any;
  path: any = 'home';
  bPath: any;
  constructor(
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {

     if(this.auth.isLoggedInP()){
      this.showVideo=true;
      this.nomeUtente = firebase.auth().currentUser?.displayName;
    } else{
      this.showVideo=false;
    }

    //console.log(this.auth.isLoggedInP());

  }

  ngOnChanges(): void {
    if(this.auth.isLoggedInP()){
      this.showVideo=true;
    } else{
      this.showVideo=false;
    }

    this.nomeUtente = firebase.auth().currentUser?.displayName;
    }




  logOut(){
    this.auth.SignOut();
  }


  resetLocal(){
    localStorage.clear();
    this.path = localStorage.getItem('path');
    this.bPath = localStorage.getItem('bPath');
    console.log('path-->', this.path);
    console.log('bPath-->', this.bPath);
  }

}
