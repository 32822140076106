import { Modal2Component } from './../shared/modal2/modal2.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from './user';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { CrudService } from './crud.service';
import { ModalComponent } from '../shared/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  public loggedIn = false;
  path: any = 'home';
  bPath: any;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private crud: CrudService,
    private modalService: NgbModal
  ) {
    this.path = localStorage.getItem('path');
    this.bPath = localStorage.getItem('bPath');
    console.log('path-->', this.path);
    console.log('bPath-->', this.bPath);
    this.loggedIn = !!sessionStorage.getItem('user');
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        user = user;
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.setItem('user', '');
      }
    });
  }

  getUser() {
    //console.log(this.afAuth.currentUser.then( u => u?.displayName))
    return this.afAuth.currentUser.then((u) => u?.displayName);
  }

  // Sign in with email/password
  SignIn(value: any) {
    return this.afAuth
      .signInWithEmailAndPassword(value.email, value.password)
      .then((result) => {
        this.ngZone.run(() => {
          const modalRef = this.modalService.open(ModalComponent);
          modalRef.componentInstance.name = 'Login avvenuto con successo';
          setTimeout(() => {
            modalRef.close();
            console.log('path-->', this.path);
            if (this.bPath==='false'|| this.path=== null) {
              this.router.navigate([`/patdifairplay/home/`]);
            } else {
              this.router.navigate([`/patdifairplay/dettaglioVideo/${this.path}/`]);
            }
          }, 2000);

          //npm  window.location.reload();
        });
        this.SetUserData(result.user);
        this.setCurrentUser(value.email);
      })
      .catch((error) => {
        const modalRef = this.modalService.open(Modal2Component);
        modalRef.componentInstance.name =
          'Login fallito. Verificare di aver inserito correttamente email e password';

        setTimeout(() => {
          modalRef.close();
          this.router.navigate([`/patdifairplay/login/`]);
        }, 5000);
      });
  }

  // Sign up with email/password
  SignUp(value: any) {
    //console.log(value)
    return this.afAuth
      .createUserWithEmailAndPassword(value.email, value.password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        var user = firebase.auth().currentUser;
        user?.updateProfile({
          displayName: value.fullName,
        });
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.name =
          'La registrazione è avvenuta con successo';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(() => {
          modalRef.close();
          this.router.navigate([`/patdifairplay/login/`]);
        }, 2000);

        //console.log(user)
        this.SendVerificationMail();

        this.SetUserData(result.user);
        this.crud.create(value).then(() => {
          //console.log('Created new item successfully!');
        });
      })

      .catch((error) => {
        this.router.navigate([`/patdifairplay/register/`]);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then((u) => u?.sendEmailVerification());
  }

  // Reset Forggot password
  ForgotPassword(value: any) {
    return this.afAuth
      .sendPasswordResetEmail(value.email)
      .then(() => {
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.name =
          "Un'email è stata inviata alla tua casella per il recupero della password. Controlla anche nello spam o posta indesiderata";
        modalRef.componentInstance.btn = 'OK';
        setTimeout(() => {
          modalRef.close();
          this.router.navigate([`/patdifairplay/login/`]);
        }, 2000);
      })
      .catch((error) => {
        const modalRef = this.modalService.open(Modal2Component);
        modalRef.componentInstance.name =
          'Impossibile procedere con il recupero della password';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(() => {
          modalRef.close();
        }, 2000);
      });
  }

  setCurrentUser(email: string): void {
    sessionStorage.setItem('user', email);
    this.loggedIn = true;
  }

  // Returns true when user is looged in and email is verified
  isLoggedIn(): boolean {
    var userL: any = localStorage.getItem('user');
    const user = JSON.parse(userL);
    return user !== null;
  }

  // Auth logic to run auth providers
  AuthLogin(provider: firebase.auth.AuthProvider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['patdifairplay/home']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
    //console.log(userData)
  }
  // Sign out
  SignOut() {
    return this.afAuth
      .signOut()
      .then(() => {
        sessionStorage.removeItem('user');
        this.loggedIn = false;
        localStorage.removeItem('user');
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.name = 'Logout eseguito correttamente';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(() => {
          modalRef.close();

          this.router.navigate(['patdifairplay/home']);
        }, 2000);
      })
      .catch((error) => {
        const modalRef = this.modalService.open(Modal2Component);
        modalRef.componentInstance.name = 'Impossibile procedere con il logout';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(() => {
          modalRef.close();
        }, 2000);
      });
  }

  createUser(): boolean {
    var userL: any = localStorage.getItem('user');
    if (!userL || !!userL) {
      return true;
    } else {
      return false;
    }
  }

  isLoggedInP() {
    return this.loggedIn;
  }
}
