import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
    ) { }

  canActivate(){
    //console.log(this.authService.isLoggedIn())
    if ( this.authService.isLoggedIn()){
        return true;
    }else {
      this.router.navigate(['patdifairplay/home']);
    return false;

    }
}
}
