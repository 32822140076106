import { Modal2Component } from './../../shared/modal2/modal2.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  user_id: string = "user_xQxfGCt0TeIoIfr6o2dHD";
  serviceID: string = 'service_efiku4l';
  templateID: string = 'template_pq0eqni';

  ngOnInit(): void {
  }


  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm(`${this.serviceID}`, `${this.templateID}`, e.target as HTMLFormElement, `${this.user_id}`)
      .then((result: EmailJSResponseStatus) => {
       // console.log(result.text);
      })
      .then(()=> {
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.name = 'Iscrizione alla news letter avvenuta con successo!';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(()=>{
  modalRef.close();

     }, 300);
      },
      error=> {
        const modalRef = this.modalService.open(Modal2Component);
        modalRef.componentInstance.name = 'La registrazione alla newsletter non è andata a buon fine. Riprovare!';
        modalRef.componentInstance.btn = 'OK';
        setTimeout(()=>{
          modalRef.close();

             }, 300);
      })
  }


}




