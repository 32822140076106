<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light lighten-5" [containerInside]="true">

    <!-- Navbar brand -->
    <mdb-navbar-brand> <img src="assets/logo/fenice_logo.png" alt="" width="55" height="auto" class="d-inline-block " [routerLink]="['patdifairplay/home']" style="outline: none; width: 100px; padding-right: 20px;"></mdb-navbar-brand>
    <mdb-navbar-brand> <img src="assets/logo/nuovo_logo_pat.png" alt="" width="55" height="auto" class="d-inline-block " [routerLink]="['patdifairplay/home']" style="outline: none; width: 100px;"></mdb-navbar-brand>

    <!-- Collapsible content -->
    <links class="align-self-center" style="text-align: center;">

        <!-- Links -->
        <ul class="navbar-nav mr-auto" style="margin-right: 0px!important;margin-right: auto;margin-left: auto;">
            <li class="nav-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link waves-light" [routerLink]="['patdifairplay/home']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">Home</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link waves-light" [routerLink]="['patdifairplay/videoCorsi']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">Video Corsi</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link waves-light" [routerLink]="['patdifairplay/progetto']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">Progetto</a>
            </li>

            <!-- Dropdown -->
            <li class="nav-item dropdown" dropdown [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect style="color: #00a9bb;">
              Accedi<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a *ngIf="!auth.isLoggedInP()" (click)="resetLocal()" class="dropdown-item waves-light" [routerLink]="['patdifairplay/login']" mdbWavesEffect style="color: #00a9bb;">Login</a>
                    <a *ngIf="!auth.isLoggedInP()" (click)="resetLocal()" class="dropdown-item waves-light" [routerLink]="['patdifairplay/registrati']" mdbWavesEffect style="color: #00a9bb;">Registrati</a>
                    <a *ngIf="auth.isLoggedInP()" (click)="resetLocal()" class="dropdown-item waves-light"  (click)="logOut()" mdbWavesEffect style="color: #00a9bb;">LogOut</a>
                </div>
            </li>

            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
              <a class="nav-link waves-light" [routerLink]="['patdifairplay/sostenitori']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">I sostenitori</a>
          </li>

            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link waves-light" [routerLink]="['patdifairplay/contatti']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">Contatti</a>
            </li>

            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link waves-light" [routerLink]="['patdifairplay/privacy']" mdbWavesEffect style="color: #00a9bb;" (click)="resetLocal()">Privacy</a>
            </li>

        </ul>
        <!-- Links -->

        <ul class="navbar-nav ml-auto ">

        <li class=" nav-item " >
                <a class="nav-link ">
                 <div class="row">
                  <button type="button" placement="left" ngbTooltip="Login non eseguito" style="width: 100%; background-color: transparent; border: none; outline: none;">
                    <i *ngIf="!auth.isLoggedInP()" class="fa fa-user-circle fa-2x " style="color:rgb(150, 150, 145) ; " ></i>
                  </button>
                    <button type="button" style="width: 100%; background-color: transparent; border: none; outline: none;">
                      <i *ngIf="auth.isLoggedInP()"class="fa fa-user-circle fa-2x " style="color:rgb(62, 160, 103) ; " ></i>
                    </button>
                 </div>
                </a>
            </li>

        </ul>
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->
