<div class="boxModale">

    <div class="modal-body">
        <p style="margin-left: auto;margin-right: auto;text-align: center;margin-top: 10%;font-size: 240%; line-height: 40px">{{name}}!</p>
        <div style="
    margin-left: auto;
    margin-right: auto;
    text-align: center;">

            <img src="assets/no.png" alt="" style="
          width: 28%;
          margin-top: 11%;
      ">

            <!-- <button type="button" (click)="activeModal.dismiss('Cross click')" class="btn  btn-lg" id="btnSave" style="width: 70%;background-color: #00a9bb;margin-top: 25%;">{{btn}}</button>-->
        </div>
    </div>