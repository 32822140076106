import { AuthGuard } from './services/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path:'', redirectTo: 'patdifairplay/home', pathMatch:'full'},
  { path: 'patdifairplay/home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
//   { path: 'chinesiologi_a_casa_tua', loadChildren: () => import('./features/chinesiologi/chinesiologi.module').then(m => m.ChinesiologiModule) },
 //  { path: 'sponsor', loadChildren: () => import('./features/sponsor/sponsor.module').then(m => m.SponsorModule) },
//   { path: 'calendario_lezioni', loadChildren: () => import('./features/calendario/calendario.module').then(m => m.CalendarioModule) },
   { path: 'patdifairplay/contatti', loadChildren: () => import('./features/contatti/contatti.module').then(m => m.ContattiModule) },
   { path: 'patdifairplay/progetto', loadChildren: () => import('./features/project/project.module').then(m => m.ProjectModule) },
   { path: 'patdifairplay/login', loadChildren: () => import('./features/accedi/login/login.module').then(m => m.LoginModule) },
   { path: 'patdifairplay/registrati', loadChildren: () => import('./features/accedi/registrati/registrati.module').then(m => m.RegistratiModule) },
   { path: 'patdifairplay/recuperaPassword', loadChildren: () => import('./features/accedi/forgot-psw/forgot-psw.module').then(m => m.ForgotPSWModule) },
//  { path: 'videoFitness', loadChildren: () => import('./features/video-corsi/corsi-fitness/corsi-fitness.module').then(m => m.CorsiFitnessModule) },
//  { path: 'videoPatologie', loadChildren: () => import('./features/video-corsi/corsi-patologie/corsi-patologie.module').then(m => m.CorsiPatologieModule) },
  { path: 'patdifairplay/videoCorsi', loadChildren: () => import('./features/video-corsi/video-corsi.module').then(m => m.VideoCorsiModule) },
  { path: 'patdifairplay/areaPersonale', loadChildren: () => import('./features/accedi/accont/accont.module').then(m => m.AccontModule), canActivate: [AuthGuard] },
  { path: 'patdifairplay/dettaglioVideo/:id', loadChildren: () => import('./features/video-corsi/dettaglio-video/dettaglio-video.module').then(m => m.DettaglioVideoModule) },
  { path: 'patdifairplay/sostenitori', loadChildren: () => import('./features/sostenitori/sostenitori.module').then(m => m.SostenitoriModule) },
  { path: 'patdifairplay/privacy', loadChildren: () => import('./features/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path:'**', redirectTo: 'patdifairplay/home', pathMatch:'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
