<div>
    <hr class="new1">
    <label style="color:  rgb(150, 150, 145); margin-left: 3%;">
      <img src="assets/logo/pat_fairplay_txt.png" class="d-inline-block " style="outline: none;width: 100px;padding-bottom: 4px;"> è il progetto che promuove l'attività fisica mettendo a disposizione lezioni da remoto o in presenza di istruttori laureati in scienze motorie.
  </label>
    <div  class="footer-cent">
        <div class="container">

            <div class="row" style="margin-top: 1%;text-align: right;">

                <div class="col-sm-3" style="margin-top: 1%; width: 20%;">

                    <i class="fa fa-envelope fa-3x"></i>

                </div>

                <div class="col-sm-8" style="margin-top: 1%;text-align: left; width: 80%;">
                  <label>Iscriviti alla nostra News Letter!<br>
                    Rimani aggiornato sui nuovi corsi e per conoscere le lezioni più adatte alle tue necessità</label><br>

              </div>

            </div>
            <br>

            <div class="row">

                <div class="col-sm">




                    <form class="contact-form" (submit)="sendEmail($event)">
                        <div class="input-group mb-3 formGroup">
                            <input type="email" name="from_name" #emailIn aria-label="email" aria-describedby="basic-addon1" style="border-radius: 20px;border: none;width: 44%;padding-left: 10px;margin-left: auto;margin-right: 0px;border-top-right-radius: 0;border-bottom-right-radius: 0;"
                                placeholder="Email">
                            <button type="submit" class="input-group-text" id="basic-addon1" style="margin-left: 0px;margin-right: auto;border-top-right-radius: 20px;border-bottom-right-radius: 20px; " [disabled]="!emailIn.value">
                          <i class="fa fa-chevron-circle-right" style="color:grey!important"></i>
                        </button>
                        </div>
                    </form>






                </div>
            </div>
            <div class="row" style="margin-bottom: 1%;">
                <div class="col-sm">
                    <label>Seguici su</label><br>
                    <a href="https://www.facebook.com/Progetto-PAT-102357098608185" target="_blank">
                    <i class="fa fa-facebook-f fa-2x" style="margin-left: 10px; margin-right: 10px;"></i></a>
                    <a href="https://www.youtube.com/channel/UCZxJYoZSjY700SZyWvvPXMw" target="_blank">
                        <i class="fa fa-youtube fa-2x" style="margin-left: 10px; margin-right: 10px;"></i></a>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label>Contatti</label><br>
                    <label>patdifairplay@progettopat.it</label><br>
                    <label>Cel. 375 5522354</label><br>
                    <label>Tel. 06 88932982</label><br>

                </div>
            </div>
        </div>
    </div>
</div>
