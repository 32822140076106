
import { Router } from '@angular/router';
import { User } from './user';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { map } from 'rxjs/operators';
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { Subject } from 'rxjs';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private dbPath = '/user';
  userId: string;
  userRef: any;
  uid: any;
  username: any;
  email: any;
  userDetails: any;

  constructor(
    private db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {

    this.userRef = firebase.database().ref(`user/`);

  }

  getAll() {
   return   this.db.list('user');
  }

  create(user: any): any {
   // console.log(user);
this.email = user?.email;
this.email = this.email.replace(/[^a-zA-Z ]/g, "");
    this.userRef = firebase.database().ref(`user/`).child(`${this.email}`);
    this.userRef.set(user);
    const listObservable = this.userRef.snapshotChanges();
    listObservable.subscribe();
    setTimeout(()=>{
      this.router.navigate([`/patdifairplay/login/`]);
 }, 300);
  }

  update(key: string, value: any): void {
    this.userRef.update(key, value).catch((error: any) => console.log(error));

  }

  delete(key: string): void {
    this.userRef.remove(key).catch((error: any) => console.log(error));
  }











}
